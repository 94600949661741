import React, {  useEffect } from "react";
import Loading from './Loading';
import axios from 'axios';

function LoadingCancelled(props) {
    
    useEffect(() =>{
    console.log(props);
    axios.get("https://api.plunes.com/paymentControl/cancelled/"+ props.match.params.payment_id)
      .then(console.log("success"))
      .catch(console.log("error"));
    },
    [props.id]
  );
  return (
    <Loading/>
  );
}

export default LoadingCancelled;
