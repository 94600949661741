import React, { useState, useEffect } from "react";
import Loading from './Loading';
import axios from 'axios'

function LoadingFailure(props) {
  console.log("Show error")
  useEffect(() =>{
    console.log(props);
    axios.post("https://api.plunes.com/paymentControl/error/"+ props.match.params.payment_id)
      .then(console.log("success"))
      .catch(console.log("error"));
    }
  );
  return (
    <Loading/>
  );
}

export default LoadingFailure;